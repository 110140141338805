import styled from 'styled-components';

export const HistorySectionWrapper = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 78px 0 72px;
    gap: 54px;

    width: 100%;
`;

export const TitleSection = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
`;

export const Title = styled.h4`
    font-family: inter, sans-serif;
    font-size: 24px;
    font-weight: 900;
    line-height: 29px;
    letter-spacing: 0.12em;
    color: #000000;

    @media (max-width: 834px) {
        font-size: 22px;
        line-height: 27px;
    }
`;

export const RoadmapSection = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    padding-left: 45px;

    width: 100%;

    @media (max-width: 500px) {
        padding-left: 0;
    }
`;

export const RoadmapItem = styled.article`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
`;

export const YearSection = styled.section`
    width: 48px;
`;

export const Year = styled.span`
    font-family: inter, sans-serif;
    font-size: 18px;
    font-weight: 900;
    line-height: 22px;
    color: #000000;

    display: block;
    width: 48px;

    @media (max-width: 500px) {
        font-size: 16px;
        line-height: 19px;
    }
`;

export const DescriptionSection = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 45px;
    gap: 0.5rem;

    width: 100%;
`;

export const Month = styled.span`
    font-family: Pretendard, sans-serif;
    font-size: 18px;
    font-weight: 900;
    line-height: 22px;
    color: #000000;

    @media (max-width: 500px) {
        font-size: 16px;
        line-height: 19px;
    }
`;

export const Description = styled.span`
    font-family: Pretendard, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    color: #000000;

    white-space: pre-wrap;
    word-break: keep-all;

    @media (max-width: 500px) {
        font-size: 16px;
        line-height: 19px;
    }
`;

export const SeparatorSection = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    align-self: stretch;
    z-index: 0;
`;

export const UpperLine = styled.section<{ $isFirst: boolean }>`
    height: 7px;
    width: 0;
    border-left: ${({ $isFirst }) => `1px dashed ${$isFirst ? 'transparent' : '#000000'}`};

    @media (max-width: 500px) {
        height: 4px;
    }
`;

export const MiddleSection = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1px 0;

    height: 10px;
`;

export const IconWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    transform: translateX(-3.5px);
`;

export const BottomLine = styled.section<{ $isLast: boolean }>`
    display: flex;
    flex-direction: column;
    -webkit-box-pack: start;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1 1 0;
    width: 0;

    border-left: ${({ $isLast }) => `1px dashed ${$isLast ? 'transparent' : '#000000'}`};
    align-self: stretch;
`;
