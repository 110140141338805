import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { HomeSubSectionType } from '@pages/HomePage/HomePage';

import * as S from './AboutSection.styles';

interface AboutSectionProps {
    onChangeInView: (visibleSection: HomeSubSectionType | null) => void;
}

const AboutSection = ({ onChangeInView }: AboutSectionProps) => {
    useEffect(() => {
        const aboutSection = document.getElementById('about-section');
        const topSection = document.getElementById('top-section');

        if (aboutSection && topSection) {
            const detectInView = () => {
                const scrollPosition = aboutSection!.getBoundingClientRect().top;
                if (scrollPosition <= 0) onChangeInView('about-section');
                else onChangeInView(null);
            };
            topSection.addEventListener('scroll', detectInView);
            return () => topSection.removeEventListener('scroll', detectInView);
        }
    }, []);

    return (
        <S.AboutSectionWrapper id="about-section">
            <S.TitleSection>
                <S.Title>
                    <FormattedMessage id="about-section.title" />
                </S.Title>
            </S.TitleSection>
            <S.DescriptionWrapper>
                <S.Description>
                    <FormattedMessage id="about-section.description" />
                </S.Description>
            </S.DescriptionWrapper>
        </S.AboutSectionWrapper>
    );
};

export default AboutSection;
