import { createGlobalStyle } from 'styled-components';

import { Reset } from '@styles/reset';

export const GlobalStyle = createGlobalStyle`
    ${Reset()}
    
    :root {
        --vh: 100%;
    }

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        color: inherit;

        font-family: Inter, 'Noto Sans KR', sans-serif;

        &.en {
            font-family: Inter, 'Noto Sans KR', sans-serif;
        }

        &.ko {
            font-family: 'Noto Sans KR', Inter, sans-serif;
        }
    }

    body {
        margin: 0 auto;
        overflow-x: hidden;
        overflow-y: scroll;

        background-color: #F9F9F9;
        color: #000000;

        // 디자인에 맞게 최대 크기 설정
        //width: max(100vw, 1920px);
        /*margin-left: -4px;*/
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE 10+ */
        overflow-x: hidden;
        overflow-y: scroll;

        -webkit-overflow-scrolling: touch;
    }

    /* total width */
    *::-webkit-scrollbar {
        background-color: transparent;
        width: 2px;
        height: 0;
        margin-right: -2px;
        z-index: 999999;
    }

    /* background of the scrollbar except button or resizer */
    *::-webkit-scrollbar-track {
        background-color: transparent;
    }

    /* scrollbar itself */
    *::-webkit-scrollbar-thumb {
        background-color: #babac0;
        border-radius: 4px;
        border: 0px solid #ffffff;
        background-clip: padding-box;
        visibility: hidden;
    }

    /* set button(top and bottom of the scrollbar) */
    *::-webkit-scrollbar-button {
        display: none;
    }

    /* scrollbar when scrollbar is hovered */
    *::-webkit-scrollbar-thumb:hover {
        visibility: visible;
    }

    *.active-scroll-bar::-webkit-scrollbar-thumb {
        visibility: visible;
    }

    *.active-scroll-bar::-webkit-scrollbar-thumb:hover {
        visibility: visible;
    }
    a {
        text-decoration: none;
        color: inherit;
    }
    ol,
    ul {
        list-style: none;
    }
    li {
        list-style: none;
    }
    button {
        cursor: pointer;
        border: 0;
        background-color: inherit;
    }
    div {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
    div::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }
    span {
        line-height: 1.5;
    }
    textarea {
        font-size: 16px;
    }
`;
