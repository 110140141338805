import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { HomeSubSectionType } from '@pages/HomePage/HomePage';

import * as S from './MessageSection.styles';

interface MessageSectionProps {
    onChangeInView: (visibleSection: HomeSubSectionType | null) => void;
}

const MessageSection = ({ onChangeInView }: MessageSectionProps) => {
    useEffect(() => {
        const messageSection = document.getElementById('message-section');
        const topSection = document.getElementById('top-section');

        if (messageSection && topSection) {
            const detectInView = () => {
                const scrollPosition = messageSection!.getBoundingClientRect().top;
                if (scrollPosition <= 300) onChangeInView('message-section');
            };
            topSection.addEventListener('scroll', detectInView);
            return () => topSection.removeEventListener('scroll', detectInView);
        }
    }, []);

    return (
        <S.MessageSectionWrapper id="message-section">
            <S.TitleSection>
                <S.Title>
                    <FormattedMessage id="message-section.title" />
                </S.Title>
            </S.TitleSection>
            <S.DescriptionWrapper>
                <S.Description>
                    <FormattedMessage id="message-section.description" />
                </S.Description>
            </S.DescriptionWrapper>
        </S.MessageSectionWrapper>
    );
};

export default MessageSection;
