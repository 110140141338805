import { ReactNode } from 'react';

import { Header } from '@components/Header/Header';

import * as S from './Layout.styles';

interface LayoutProps {
    children: ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
    return (
        <S.LayoutWrapper id={'top-section'}>
            <Header />
            {children}
        </S.LayoutWrapper>
    );
};
