import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import DotIcon from '@assets/DotIcon.svg';

import { HomeSubSectionType } from '@pages/HomePage/HomePage';

import * as S from './HistorySection.styles';

interface HistorySectionProps {
    onChangeInView: (visibleSection: HomeSubSectionType | null) => void;
}

const HistorySection = ({ onChangeInView }: HistorySectionProps) => {
    useEffect(() => {
        const historySection = document.getElementById('history-section');
        const topSection = document.getElementById('top-section');

        if (historySection && topSection) {
            const detectInView = () => {
                const scrollPosition = historySection!.getBoundingClientRect().top;
                if (scrollPosition <= 0) onChangeInView('history-section');
                // else onChangeInView('about-section');
            };
            topSection.addEventListener('scroll', detectInView);
            return () => topSection.removeEventListener('scroll', detectInView);
        }
    }, []);

    return (
        <S.HistorySectionWrapper id="history-section">
            <S.TitleSection>
                <S.Title>
                    <FormattedMessage id="history-section.title" />
                </S.Title>
            </S.TitleSection>
            <S.RoadmapSection>
                {Array.from({ length: 6 }, (_, i) => i + 1).map((item) => {
                    return (
                        <S.RoadmapItem key={item}>
                            <S.YearSection>
                                <S.Year>
                                    {![2, 3, 4].includes(item) ? (
                                        <FormattedMessage id={`history-section.roadmap.item${item}.year`} />
                                    ) : (
                                        <>&nbsp;</>
                                    )}
                                </S.Year>
                            </S.YearSection>
                            <S.SeparatorSection>
                                <S.UpperLine $isFirst={item === 1} />
                                <S.MiddleSection>
                                    <S.IconWrapper>
                                        <img src={DotIcon} alt="Dot" width={8} height={8} />
                                    </S.IconWrapper>
                                </S.MiddleSection>
                                <S.BottomLine $isLast={item === 6} />
                            </S.SeparatorSection>
                            <S.DescriptionSection>
                                <S.Month>
                                    <FormattedMessage id={`history-section.roadmap.item${item}.month`} />
                                </S.Month>
                                <S.Description>
                                    <FormattedMessage id={`history-section.roadmap.item${item}.description`} />
                                </S.Description>
                                {item === 2 && (
                                    <S.Description>
                                        <FormattedMessage id={`history-section.roadmap.item${item}.description2`} />
                                    </S.Description>
                                )}
                            </S.DescriptionSection>
                        </S.RoadmapItem>
                    );
                })}
            </S.RoadmapSection>
        </S.HistorySectionWrapper>
    );
};

export default HistorySection;
