import styled from 'styled-components';

export const AboutSectionWrapper = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 78px 0 72px;
    gap: 54px;

    width: 100%;
`;

export const TitleSection = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
`;

export const Title = styled.h4`
    font-family: inter, sans-serif;
    font-size: 24px;
    font-weight: 900;
    line-height: 29px;
    letter-spacing: 0.12em;
    color: #000000;

    @media (max-width: 834px) {
        font-size: 22px;
        line-height: 27px;
    }
`;

export const DescriptionWrapper = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    width: 100%;
`;

export const Description = styled.span`
    font-family: Pretendard, sans-serif;
    font-size: 19px;
    font-weight: 400;
    line-height: 34px;
    color: #000000;

    white-space: pre-wrap;
    word-break: keep-all;
    text-align: center;

    width: 100%;

    @media (max-width: 834px) {
        font-size: 18px;
        line-height: 30px;

        white-space: unset;
    }
`;
