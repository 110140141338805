import styled from 'styled-components';

export const OverviewSectionWrapper = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 78px 0 72px;
    gap: 54px;

    width: 100%;
`;

export const TitleSection = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
`;

export const Title = styled.h4`
    font-family: inter, sans-serif;
    font-size: 24px;
    font-weight: 900;
    line-height: 29px;
    letter-spacing: 0.12em;
    color: #000000;

    @media (max-width: 834px) {
        font-size: 22px;
        line-height: 27px;
    }
`;

export const DescriptionWrapper = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    width: 100%;
`;

export const DescriptionItem = styled.article`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 36px 0;

    width: 100%;

    border-top: 2px solid #f3f3f3;

    &:last-of-type {
        border-bottom: 2px solid #f3f3f3;
    }

    @media (max-width: 500px) {
        flex-direction: column;
        padding: 24px 0;
        gap: 18px;
    }
`;

export const ItemLabelSection = styled.section`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 231px;

    @media (max-width: 500px) {
        width: 100%;
    }
`;

export const ItemLabel = styled.span`
    font-family: inter, sans-serif;
    font-size: 18px;
    font-weight: 900;
    line-height: 22px;
    color: #000000;

    @media (max-width: 500px) {
        font-size: 16px;
        line-height: 19px;
    }
`;

export const ItemValueSection = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 28px;

    width: 100%;

    & iframe {
        border: 0;
        aspect-ratio: 16/9;
    }

    @media (max-width: 744px) {
        & iframe {
            aspect-ratio: 1/1;
        }
    }
`;

export const ItemValue = styled.span`
    font-family: inter, sans-serif;
    font-size: 18px;
    line-height: 22px;
    color: #000000;

    @media (max-width: 500px) {
        font-size: 16px;
        line-height: 19px;
    }
`;
