import styled from 'styled-components';

export const LayoutWrapper = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 auto;

    width: min(1440px, 100vw);
    height: 100vh;
    overflow-y: scroll;

    background-image: url('/home/background.png');
    background-repeat: no-repeat;
    background-position: right -45px top 45px;
    background-size: 796px 873px;

    @media (max-width: 1280px) {
        background-position: right -122px top 45px;
        background-size: 796px 873px;
    }

    @media (max-width: 1024px) {
        background-position: right -250px top 45px;
        background-size: 796px 873px;
    }

    @media (max-width: 834px) {
        background-position: right -345px top 45px;
        background-size: 796px 873px;
    }

    @media (max-width: 744px) {
        background-position: right -390px top 45px;
        background-size: 796px 873px;
    }

    @media (max-width: 500px) {
        background-position: right -238px top 45px;
        background-size: 522px 572px;
    }

    @media (max-width: 430px) {
        background-position: right -300px top 45px;
        background-size: 549px 602px;
    }

    @media (max-width: 393px) {
        background-position: right -319px top 45px;
        background-size: 549px 602px;
    }

    @media (max-width: 375px) {
        background-position: right -328px top 45px;
        background-size: 549px 602px;
    }

    @media (max-width: 320px) {
        background-position: right -355px top 45px;
        background-size: 549px 602px;
    }
`;
