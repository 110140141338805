import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { HomeSubSectionType } from '@pages/HomePage/HomePage';

import * as S from './OverviewSection.styles';

interface OverviewSectionProps {
    onChangeInView: (visibleSection: HomeSubSectionType | null) => void;
}

const OverviewSection = ({ onChangeInView }: OverviewSectionProps) => {
    useEffect(() => {
        const overviewSection = document.getElementById('overview-section');
        const topSection = document.getElementById('top-section');

        if (overviewSection && topSection) {
            const detectInView = () => {
                const scrollPosition = overviewSection!.getBoundingClientRect().top;
                if (scrollPosition <= 0) onChangeInView('overview-section');
                // else onChangeInView('about-section');
            };
            topSection.addEventListener('scroll', detectInView);
            return () => topSection.removeEventListener('scroll', detectInView);
        }
    }, []);

    return (
        <S.OverviewSectionWrapper id="overview-section">
            <S.TitleSection>
                <S.Title>
                    <FormattedMessage id="overview-section.title" />
                </S.Title>
            </S.TitleSection>
            <S.DescriptionWrapper>
                {['company', 'ceo', 'established', 'contact', 'office'].map((item) => {
                    return (
                        <S.DescriptionItem key={item}>
                            <S.ItemLabelSection>
                                <S.ItemLabel>
                                    <FormattedMessage id={`overview-section.item.${item}.label`} />
                                </S.ItemLabel>
                            </S.ItemLabelSection>
                            <S.ItemValueSection>
                                <S.ItemValue>
                                    <FormattedMessage id={`overview-section.item.${item}.description`} />
                                </S.ItemValue>
                                {item === 'office' && (
                                    // <S.IFrameContainer>
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3165.1128298280546!2d127.06131107553813!3d37.50525692764015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca43e552a660f%3A0x6fc7ecf2c2dbc559!2s3rd%20Floor%2C%20557%20Yeoksam-ro%2C%20Gangnam-gu%2C%20Seoul!5e0!3m2!1sen!2skr!4v1715830225756!5m2!1sen!2skr"
                                        width="100%"
                                        height="auto"
                                        allowFullScreen={false}
                                        loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"
                                    />
                                    // </S.IFrameContainer>
                                )}
                            </S.ItemValueSection>
                        </S.DescriptionItem>
                    );
                })}
            </S.DescriptionWrapper>
        </S.OverviewSectionWrapper>
    );
};

export default OverviewSection;
