import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useCheckScreenSize } from '@hooks/common/useCheckScreenSize';

import AboutSection from '@pages/HomePage/components/AboutSection/AboutSection';
import HistorySection from '@pages/HomePage/components/HistorySection/HistorySection';
import MainSection from '@pages/HomePage/components/MainSection/MainSection';
import MessageSection from '@pages/HomePage/components/MessageSection/MessageSection';
import OverviewSection from '@pages/HomePage/components/OverviewSection/OverviewSection';

import * as S from './HomePage.styles';

export type HomeSubSectionType = 'about-section' | 'overview-section' | 'history-section' | 'message-section';

const HomePage = () => {
    const [visibleSection, setVisibleSection] = useState<HomeSubSectionType | null>(null);

    const onChangeInView = (inView: HomeSubSectionType | null) => setVisibleSection(inView);

    const onClickSection = (id: HomeSubSectionType) => {
        const section = document.getElementById(id);
        const topSection = document.getElementById('top-section');

        if (section && topSection) {
            const sectionTop = section.getBoundingClientRect().top;
            const target = topSection.scrollTop + sectionTop;
            topSection.scrollTo({
                top: target,
                behavior: 'smooth',
            });
        }
    };

    const isDesktop = useCheckScreenSize(1024);

    return (
        <S.HomePageWrapper>
            <MainSection />
            <S.ContentSection>
                <S.HomeContentSection id={'home-content-section'}>
                    {!isDesktop && (
                        <S.MobileMenuSection>
                            <S.MobileMenu
                                className={visibleSection === 'about-section' ? 'active' : ''}
                                onClick={() => onClickSection('about-section')}
                            >
                                <span>
                                    <FormattedMessage id="menu.item.label.about-section" />
                                </span>
                            </S.MobileMenu>
                            <S.MobileMenu
                                className={visibleSection === 'overview-section' ? 'active' : ''}
                                onClick={() => onClickSection('overview-section')}
                            >
                                <span>
                                    <FormattedMessage id="menu.item.label.overview-section" />
                                </span>
                            </S.MobileMenu>
                            <S.MobileMenu
                                className={visibleSection === 'history-section' ? 'active' : ''}
                                onClick={() => onClickSection('history-section')}
                            >
                                <span>
                                    <FormattedMessage id="menu.item.label.history-section" />
                                </span>
                            </S.MobileMenu>
                            <S.MobileMenu
                                className={visibleSection === 'message-section' ? 'active' : ''}
                                onClick={() => onClickSection('message-section')}
                            >
                                <span>
                                    <FormattedMessage id="menu.item.label.message-section" />
                                </span>
                            </S.MobileMenu>
                        </S.MobileMenuSection>
                    )}
                    <AboutSection onChangeInView={onChangeInView} />
                    <OverviewSection onChangeInView={onChangeInView} />
                    <HistorySection onChangeInView={onChangeInView} />
                    <MessageSection onChangeInView={onChangeInView} />
                </S.HomeContentSection>
                {isDesktop && (
                    <S.DesktopMenuSection>
                        <S.DesktopMenu
                            className={visibleSection === 'about-section' ? 'active' : ''}
                            onClick={() => onClickSection('about-section')}
                        >
                            <span>
                                <FormattedMessage id="menu.item.label.about-section" />
                            </span>
                        </S.DesktopMenu>
                        <S.DesktopMenu
                            className={visibleSection === 'overview-section' ? 'active' : ''}
                            onClick={() => onClickSection('overview-section')}
                        >
                            <span>
                                <FormattedMessage id="menu.item.label.overview-section" />
                            </span>
                        </S.DesktopMenu>
                        <S.DesktopMenu
                            className={visibleSection === 'history-section' ? 'active' : ''}
                            onClick={() => onClickSection('history-section')}
                        >
                            <span>
                                <FormattedMessage id="menu.item.label.history-section" />
                            </span>
                        </S.DesktopMenu>
                        <S.DesktopMenu
                            className={visibleSection === 'message-section' ? 'active' : ''}
                            onClick={() => onClickSection('message-section')}
                        >
                            <span>
                                <FormattedMessage id="menu.item.label.message-section" />
                            </span>
                        </S.DesktopMenu>
                    </S.DesktopMenuSection>
                )}
            </S.ContentSection>
        </S.HomePageWrapper>
    );
};

export default HomePage;
