import { FormattedMessage } from 'react-intl';

import CTAIcon from '@assets/CTAIcon.svg';

import * as S from './MainSection.styles';

const MainSection = () => {
    const onClickCTAButton = () => window.open('https://bcto.me', '_blank');

    return (
        <S.MainSectionWrapper>
            <S.TitleSection>
                <S.Title>
                    <FormattedMessage id="main-section.title" />
                </S.Title>
                <S.Description>
                    <FormattedMessage id="main-section.description" />
                </S.Description>
            </S.TitleSection>
            <S.CTAButton onClick={onClickCTAButton}>
                <span>
                    <FormattedMessage id="main-section.cta-label" />
                </span>
                <S.IconWrapper>
                    <img src={CTAIcon} alt="To BCTO" />
                </S.IconWrapper>
            </S.CTAButton>
        </S.MainSectionWrapper>
    );
};

export default MainSection;
