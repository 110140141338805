import React from 'react';
import ReactDOM from 'react-dom/client';

import { GlobalStyle } from '@src/styles/GlobalStyle';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <>
        <GlobalStyle />
        <App />
    </>,
);
