import styled from 'styled-components';

export const MainSectionWrapper = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 54px 0 8px;

    gap: 24px;

    width: 100%;
`;

export const TitleSection = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
`;

export const Title = styled.h4`
    font-family: inter, sans-serif;
    font-size: 40px;
    font-weight: bolder;
    line-height: 50px;
    color: #000000;

    @media (max-width: 834px) {
        font-size: 36px;
    }

    @media (max-width: 500px) {
        font-size: 35px;
        width: 200px;
    }
`;

export const Description = styled.span`
    font-family: Pretendard, sans-serif;
    font-size: 19px;
    font-weight: 400;
    line-height: 28px;
    color: #000000;

    white-space: pre-wrap;
    word-break: keep-all;

    @media (max-width: 500px) {
        font-size: 17px;
    }
`;

export const CTAButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    & span {
        font-family: Pretendard, sans-serif;
        font-size: 18px;
        font-weight: 500;
        line-height: 21px;
        color: #000000;
    }

    @media (max-width: 500px) {
        & span {
            font-size: 16px;
            line-height: 19px;
        }
    }
`;

export const IconWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
