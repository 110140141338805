import React from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';

import { useAtomValue } from 'jotai';
import message_en from 'src/i18n/en.json';
import message_ko from 'src/i18n/ko.json';

import { Layout } from '@components/Layout/Layout';

import HomePage from '@pages/HomePage/HomePage';

import { userLanguageAtom } from '@src/atoms/common';

type UserLanguage = 'en' | 'ko';

const messages: { [key in UserLanguage]?: Record<string, string> } = {
    en: message_en,
    ko: message_ko,
};

const i18nErrorLogger = (errMsg: any) => {
    console.log(errMsg);
};

const App = () => {
    const userLanguage = useAtomValue(userLanguageAtom);
    const locale = userLanguage === 'ko' ? 'ko' : 'en';

    return (
        <BrowserRouter>
            <IntlProvider defaultLocale={'en'} locale={locale} messages={messages[locale]} onError={i18nErrorLogger}>
                <Layout>
                    <HomePage />
                </Layout>
            </IntlProvider>
        </BrowserRouter>
    );
};

export default App;
