import styled from 'styled-components';

export const HomePageWrapper = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 74px 120px 156px;
    margin: 0 auto;

    //width: 100%;

    width: min(1440px, 100vw);
    height: 100%;
    //overflow-y: scroll;

    @media (max-width: 1280px) {
        padding: 74px 100px 156px;
    }

    @media (max-width: 1024px) {
        padding: 74px 80px 156px;
    }

    @media (max-width: 834px) {
        padding: 74px 74px 156px;
    }

    @media (max-width: 744px) {
        padding: 74px 70px 156px;
    }

    @media (max-width: 500px) {
        padding: 74px 40px 156px;
    }

    @media (max-width: 393px) {
        padding: 74px 30px 126px;
    }

    @media (max-width: 320px) {
        padding: 74px 25px 100px;
    }
`;

export const ContentSection = styled.section`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 100px;

    width: 100%;

    @media (max-width: 1280px) {
        gap: 95px;
    }
`;

export const HomeContentSection = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 149px;

    flex: 1;

    @media (max-width: 1024px) {
        padding-top: 130px;
    }

    height: 100%;
`;

export const DesktopMenuSection = styled.aside`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 18px;

    width: 100px;

    position: sticky;
    top: calc(204px + 151px + 74px); // (height of main section + header + top page padding)
`;

export const DesktopMenu = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &::before {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        content: '';
        visibility: hidden;

        transform: scale(0);

        transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
        color: transparent;
    }

    & span {
        font-family: inter, sans-serif;
        font-size: 14px;
        line-height: 17px;
        font-weight: 900;
        letter-spacing: 0.2rem;
        color: #000000;

        padding-left: 0;

        transition: padding-left 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    &.active {
        &::before {
            content: '⏺';
            visibility: visible;
            transform: scale(1);

            color: #009966;
        }

        & span {
            color: #009966;
            padding-left: 8px;
        }
    }

    @media (max-width: 1024px) {
        display: none;
    }
`;

export const MobileMenuSection = styled.section`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;

    width: 100%;

    @media (max-width: 1024px) {
        padding-bottom: 52px;
    }
`;

export const MobileMenu = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    & span {
        font-family: inter, sans-serif;
        font-size: 14px;
        line-height: 17px;
        font-weight: 900;
        letter-spacing: 0.2rem;
        color: #000000;

        padding-left: 0;
    }

    &.active {
        & span {
            color: #009966;
        }
    }

    @media (min-width: 1025px) {
        display: none;
    }

    @media (max-width: 500px) {
        & span {
            font-size: 12px;
        }
    }
`;
